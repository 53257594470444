
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/4d457fdfae4a3386/packages/challenges-web-ooi/src/components/PaymentPage/Widget/index.ts';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/wix-i18n-config';


      import { PureExperimentsProvider } from '@wix/wix-experiments-react';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
}


  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://2a7edf28dde8465da7868bdd5f056f7a@sentry.wixpress.com/387',
      id: '2a7edf28dde8465da7868bdd5f056f7a',
      projectName: 'challenges-ooi-app',
      teamName: 'challenges-ooi',
      errorMonitor: true,
    };

  var translationsConfig = {"prefix":"challenges_editor","icuEnabled":true,"defaultTranslationsPath":"/home/builduser/agent00/work/4d457fdfae4a3386/packages/challenges-web-ooi/src/assets/locales/challenges_editor_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '0daa3702-0e20-48ca-ba15-ad380e00e6b6',
    name: 'PaymentPage',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
